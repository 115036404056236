/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Background from "./Background";
import { useStateContext } from "../utils/web3";
import {Alert, AlertTitle, Dialog, Snackbar} from '@mui/material';
import one from "../assets/1.png";
import two from "../assets/2.png"
import three from "../assets/3.png"
import four from "../assets/4.png"
import five from "../assets/5.png"
import six from "../assets/6.png"
import seven from "../assets/7.png"
import eight from "../assets/8.png"
import nine from "../assets/9.png"
import ten from "../assets/10.png"
import plus from "../assets/BotonPlus.png"
import minus from "../assets/BotonMinus.png"
import success from "../assets/OperationSuccessfully.png"
import SoldOut from "../assets/Sold-Out.png"
import notAcrive from "../assets/Mint-Not-Active.png"
import subtitle from "../assets/MintButtom.png"
import title from "../assets/Titulo.png"
import mintButton from "../assets/MintActive.png"
import loader from "../assets/Loader1.gif"
import cw from "../assets/ConnectWalletSign.png"

const cumcontainer = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten
]

function Royalties() {
  const {
    account,
    connectWallet,
    mint,
    isFreeMintingOpen,
    isSoldOut,
  } = useStateContext();
  const [amount, setAmount] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNotReady, setIsNotReady] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (account) {
      getData()
    }
  }, [ account ]);

  const getData = async () => {
    const isSold = await isSoldOut();
    if ( isSold ) {
      setIsSold(true)
    } else {
      const isFree = await isFreeMintingOpen();
      if (!isFree) {
        setIsNotReady(true)
      } else {
        setIsActive(true)
      }
    }
  }

  const modalProps = {
    style: {
        borderRadius: 18,
        background: 'rgba(29, 31, 32, 0.85)',
    },
  };

  const handleModal = async () => {
    if (!account) {
      connectWallet()
    } else {
      const isFree = await isFreeMintingOpen();
      if(isFree) {
        setIsOpen(true);
      }
    }
  }

  const handlePlus = async () => {
    if (amount < 10) {
      setAmount(amount+1)
    }
  }

  const handleMinus = () => {
    if (amount > 1) {
      setAmount(amount-1)
    }
  }

  const cum = async () => {
    setIsLoading(true);
    let tx:any = false;
    try {
       tx = await mint(amount);
    } catch(e) {
      console.log(e);
      setIsLoading(false);
      setOpenAlert(true);
    }
    if(tx) {
      setIsOpen(false);
      setIsLoading(false);
      setIsSuccess(true);
      setTransactionHash(tx.transactionHash);
      setAmount(1);
    }
  }

  return (
    <>
      <div className="flex-col justify-between w-full h-5/6 z-10">
        <div className=" flex items-end h-2/6 md:h-1/5">
          {
            isSold &&
            <button onClick={() => {}} className="flex h-2/6 md:h-3/6 mint-button">
                <img src={SoldOut} alt="" className="h-full w-auto"/>
            </button>
          }
          {
            isNotReady &&
            <button onClick={()=>{}} className="flex h-2/6 md:h-3/6 mint-button">
                <img src={notAcrive} alt="" className="h-full w-auto"/>
            </button>
          }
          {
            isActive &&
            <button onClick={handleModal} className="flex h-2/6 md:h-3/6 mint-button">
                <img src={mintButton} alt="" className="h-full w-auto"/>
            </button>
          }
          {
            (!isActive && !isNotReady && !isSold) && 
            <button onClick={()=> connectWallet()} className="flex h-2/6 md:h-3/6 mint-button">
              <img src={cw} alt="" className="h-full w-auto"/>
            </button>
          }
        </div>
        <div className="h-3/6">
          
        </div>
        <div className=" h-3/5 md:h-1/5">
          <a href="https://etherscan.io/address/0x37ad9DBD729F01c80467cB0B0E7547FcD31fC199" target="_blank" rel="noopener noreferrer" className=" h-full w-full" >
            <div className="w-screen h-full">
            </div>
          </a>
        </div>
      </div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} PaperProps={modalProps}>
        <div className="flex items-center justify-center modal">
          <button onClick={handleMinus} className="flex h-1/6 minus">
              <img src={minus} alt="" className="h-full w-auto plus"/>
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            <img src={title} alt="" className="title w-auto"/>
            <img src={cumcontainer[amount-1]} alt="" className="h-2/5 md:h-3/5 w-auto"/>
            {
              loading ?
              <img src={loader} alt="" className="subtitle"/>
              :
              <button onClick={cum} className="subtitle">
                <img src={subtitle} alt="" className="h-full"/>
              </button>
            }
          </div>
          <button onClick={handlePlus} className="flex h-1/6 minus">
              <img src={plus} alt="" className="h-full w-auto plus"/>
          </button>
        </div>
        <Snackbar message="Here is snackbar message" open={openAlert} autoHideDuration={30000} onClose={() => {setOpenAlert(false)}}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Ups there was an error sending your transaction. Please try again later
          </Alert>
        </Snackbar>
      </Dialog>
      <Dialog open={isSuccess} onClose={() => setIsSuccess(false)} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
          <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer" className="w-full">
            <img src={success} alt="" className="w-full"/>
          </a>
      </Dialog>
      <Background></Background>
    </>
  )
}

export default Royalties;