import {useEffect, useState} from 'react';
import constate from 'constate';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
// @ts-ignore
import WalletConnectProvider from '@walletconnect/web3-provider';
import Cumming from "../abis/Cumming.json";
import Proofs from "../abis/Proofs.json";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

import {CUMMING_CONTRACT_ADDRESS,} from "../utils/constants";

const MINT_VALUE = 0.0069;


const useCustomState = () => {
  const requiredNetworkId = 1;

  // Web3Modal
  const providerOptions = {
    walletlink: {
      package: CoinbaseWalletSDK, 
      options: {
        appName: "Web 3 Modal Demo",
        infuraId: "25437149b33e4a2185451746da28f9dd" 
      }
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "25437149b33e4a2185451746da28f9dd",
        network: 'mainnet',
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions,
  });

  // State
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string>('');

  const [network, setNetwork] = useState<number>(0);
  // @ts-ignore
  const [, setProvider] = useState<any>(null);
  const [cummingContract, setCummingContract] = useState<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if ( web3 ) {
      const cummingContractInstance = new web3.eth.Contract(
        // @ts-ignore
        Cumming.abi,
        CUMMING_CONTRACT_ADDRESS
      );

      setCummingContract(cummingContractInstance);
    }
  }, [web3]); //eslint-disable-line

  useEffect(() => {
    const intervalNetwork = setInterval(function () {
      if (web3) {
        web3.eth.net.getId((err, currentNet) => {
          // No error and change in network
          if (!err && network > 0 && currentNet !== network) {
            if (currentNet === requiredNetworkId) {
              window.location.reload();
            } else if (network === requiredNetworkId) {
              //showNetworkErrorMessage();
            }
            setNetwork(currentNet);
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(intervalNetwork);
    };
  }, [network]); //eslint-disable-line

  const gas = (count: number) => {
    switch (true) {
      case Number(count) > 0 && Number(count) <= 1:
        return "150000";
      case Number(count) > 1 && Number(count) <= 6:
        return "300000";
      case Number(count) > 6 && Number(count) <= 10:
        return "400000";
    }
    return "1005000";
  };

  const paidMint = async (amount: number) => {
    const etherValue = (MINT_VALUE * (amount as any)).toFixed(4);
    console.log(etherValue);
    const value = Web3.utils.toWei(etherValue, "ether");
    return cummingContract.methods.mint(amount).send({
      from: account,
      value,
      gas: gas(amount)
    });
  }

  const whiteListMint = async (amount: number) => {
    const proofs = getProofs();
    console.log(proofs);
    return cummingContract.methods.whiteListMint(amount, proofs).send({
      from: account,
      gas: gas(amount)
    });
  }

  const mint = async (amount:number) => {
    if(await isWhiteListOpen()) {
      return await whiteListMint(amount);
    }
    if(await isPaidMintingOpen()) {
      return await paidMint(amount);
    }
  }

  const isWhiteListOpen = async () => {
    return false;
  }

  const isInWhiteList = async () => {
    return false;
  }

  const getProofs = (): Promise<string[]> => {
    return Proofs[account.toLowerCase()];
  }

  const whiteListCap = async () => {
    return 10 - (await cummingContract.methods.whiteListCapTracker(account).call());
  }

  const isPaidMintingOpen = async () => {
    return cummingContract.methods.isMintOpen().call();
  }

  const isSoldOut = async () => {
    return await cummingContract.methods.totalSupply().call() >= 4646;
  }

  const connectWallet = async () => {
    try {
      const _provider = await web3Modal.connect();
      const _web3: Web3 = new Web3(_provider);
      setWeb3(_web3);
      setProvider(_provider);
      let _account = '';
      if (_provider && _provider.selectedAddress) {
        _account = _provider.selectedAddress;
        
      }
      if (!_account && _provider && _provider.accounts) {
        _account = _provider.accounts[0];
      }
      if (!account && _provider && _provider.address) {
        _account = _provider.address;
      }
      setAccount(_account);
      // TODO - review why _web3.eth.getAccounts() is not working
      let netId = await _web3.eth.net.getId();
      setNetwork(netId);
      //if (requiredNetworkId !== netId) showNetworkErrorMessage();
      setIsConnected(true);

    } catch (e) {
      console.log('Error > Connecting wallet');
      console.log(e);
    }
  };

  const disconnectWallet = () => {
    try {
      // @ts-ignore
      web3?.currentProvider.close();
    } catch (e) {}
    web3Modal.clearCachedProvider();
    setWeb3(null);
    setIsConnected(false);
    setAccount('');
  };

  return {
    web3,
    connectWallet,
    disconnectWallet,
    isConnected,
    account,
    mint,
    isWhiteListOpen,
    isFreeMintingOpen: isPaidMintingOpen,
    whiteListCap,
    isInWhiteList,
    isSoldOut
  };
};

const [StateProvider, useStateContext] = constate(useCustomState);

export { StateProvider, useStateContext };
